import { Container, Row, Col } from "react-bootstrap"
import { Header } from "@components/Header"
import { Footer } from "@components/Footer"
import { NavigationProps } from "@components/Navigation"
import React from "react"
import { RSS, SEO } from "../../../types/next.js"
import { CalcioComScript } from "@components/PageLayout/CalcioComScript"
import { CalcioComHead } from "@components/PageLayout/CalcioComHead"
import { Page } from "@components/Page/Page"
import { SimplifiedBookmakerBonus } from "../../../types/bookmakerBonuses"
import NewNavigation from "@components/Navigation/NewNavigation"
import DesktopAdContainer from "@components/Ads/DesktopAdContainer/DesktopAd"
import MobileAdContainer from "@components/Ads/MobileAdContainer/MobileAd"
import { useRouter } from "next/router.js"

export const PageLayout = ({
  children,
  seo,
  title,
  rss,
  navigationProps,
  newUI = false,
  prev,
  next,
  withContainer = true,
  bookmakerBonuses,
  showRightColumn = true,
  showCenterColumn = true,
  colDimensions,
}: {
  children: JSX.Element
  rss?: RSS
  seo?: SEO
  title: string
  navigationProps?: NavigationProps
  newUI?: boolean
  prev?: string
  next?: string
  withContainer?: boolean
  bookmakerBonuses?: SimplifiedBookmakerBonus[]
  showRightColumn?: boolean
  showCenterColumn?: boolean
  colDimensions?: { md: number; lg: number; xl: number }
}) => {
  let body: JSX.Element = <Page body={children} />

  const router = useRouter()

  if (withContainer) {
    const pageProps = {
      bookmakerBonuses: bookmakerBonuses || [],
      navigationProps,
      showRightColumn,
      showCenterColumn,
      colDimensions,
    }

    body = (
      <Container className="calciocom-page">
        {newUI ? (
          <Row className={"container-row g-0"}>
            <Col>{children}</Col>
          </Row>
        ) : (
          <main
            style={{
              backgroundColor: "white",
            }}
            className="container-row g-0"
          >
            <Page body={children} {...pageProps} />
          </main>
        )}
      </Container>
    )
  }

  return (
    <>
      <CalcioComHead seo={seo} rss={rss} title={title} prev={prev} next={next} />
      <CalcioComScript seo={seo} />
      {router.asPath === "/test/ads" ? <DesktopAdContainer /> : null}
      <Header navigationProps={navigationProps} />
      <NewNavigation />
      {body}
      <Footer />
      {router.asPath === "test/ads" ? <MobileAdContainer /> : null}
    </>
  )
}
