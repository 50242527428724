import React from "react"
import style from "./style.module.scss"
import AdBanner from "../AdBanner"

function MobileAdContainer() {
  return (
    <div className={style.mainContainer}>
      <AdBanner dataAdSlot={"3595303767"} />
    </div>
  )
}

export default MobileAdContainer
