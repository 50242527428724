import { useEffect } from "react"

type Props = {
  dataAdSlot: string
}

const AdBanner = (props: Props) => {
  useEffect(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ;(window.adsbygoogle = window.adsbygoogle || []).push({})
    } catch (err) {
      console.log(err)
    }
  }, [])

  return (
    <ins
      className="adsbygoogle"
      style={{
        display: "block",
        overflow: "hidden",
      }}
      data-ad-slot={props.dataAdSlot}
      data-ad-format="auto"
      data-ad-client="ca-pub-1606731980298937"
      data-full-width-responsive="true"
    />
  )
}
export default AdBanner
